import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";

const useStyles = createUseStyles((theme) => ({
  slider: {
    "& *": {
      minWidth: 0,
      minHeight: 0,
    },

    "& .slick-list": {
      maxWidth: 340,
      [theme.mUp]: {
        maxWidth: 375,
      },
    },
  },
  customDotsContainer: {
    margin: "0 auto",
    display: "grid",
    gridGap: 4,
    paddingLeft: 8,
    paddingRight: 8,
    justifyItems: "center",
    gridTemplateColumns: ({ photosCount }) => `repeat(${photosCount}, 1fr)`,
    position: "relative",
    top: "-468px",
    maxWidth: 312,
    [theme.mUp]: {
      maxWidth: 370,
    },
    "& li": {
      width: "90%",
    },
  },
  customDot: {
    height: 4,
    width: "100%",
    background: theme.colors.white,
    opacity: "0.2",
    borderRadius: "100px",
    position: "relative",
    "&.active": {
      opacity: "1",
    },
  },
  slide: {
    textAlign: "center",
    display: "flex!important",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    position: "relative",
    "& img": {
      width: 375,
      height: 477,
      borderRadius: "10px",
      objectFit: "cover",
      objectPosition: "center",
    },
  },
  photoOverlay: {
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0) 60.94%, rgba(0, 0, 0, 0.71) 100%)",
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: "10px",
  },
  blurOverlay: {
    width: "100%",
    height: "100%",
    color: theme.colors.white,
    position: "absolute",
    fontWeight: 700,
    fontSize: 20,
    backdropFilter: "blur(10px)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  textOverlay: {
    margin: 0,
    textDecoration: "underline",
    userSelect: "none",
    cursor: "pointer",
  },
}));

const RookiePhotoSlider = ({ photos, photosCount, rookieUsername, redirectAction }) => {
  const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
  const classes = useStyles({ photosCount });
  const [isSwipe, setIsSwipe] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [photoIdx, setPhotoIdx] = useState(0);
  const photoToShow = photos.slice(0, 5);
  const remainingPhotos = photosCount - 4;
  const lastPhotoUrl = photos[3]?.url;

  const settings = {
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    draggable: true,
    adaptiveHeight: true,
    afterChange: (current) => {
      setIsSwipe(true);
      setPhotoIndex(current);
    },
    beforeChange: (_, current) => {
      setPhotoIdx(current);
    },
  };

  useEffect(() => {
    if (isSwipe) {
      dataLayer.push({
        event: "photoSwipe",
        username: rookieUsername,
        photoIndex: photoIndex,
        totalPhotoCount: photosCount,
      });
    }
  }, [photoIndex]);

  return (
    <>
      <Slider {...settings} className={classes.slider}>
        {photoToShow.map(({ url, id }, idx) => (
          <div key={id} className={classes.slide}>
            {idx <= 3 ? (
              <img src={url} alt={`rookie-${idx}`} />
            ) : (
              <img src={lastPhotoUrl} alt={"rookie-last-photo"} />
            )}
            <div className={classes.photoOverlay} />
            {photoIdx === photoToShow.length - 1 &&
              idx === photoToShow.length - 1 &&
              photoToShow.length > 4 && (
                <div className={classes.blurOverlay}>
                  {t("rookie_profile_page.see_more_photos", {
                    PHOTOS_COUNT: remainingPhotos,
                    ROOKIE_NAME: rookieUsername,
                  })}
                  <p
                    className={classes.textOverlay}
                    onClick={redirectAction}
                  >
                    {t("rookie_profile_page.sign_up_now")}
                  </p>
                </div>
              )}
          </div>
        ))}
      </Slider>
      {photosCount > 1 && (
        <div className={classes.customDotsContainer}>
          {photos.map((_, idx) => (
            <div
              key={idx}
              className={`${classes.customDot} ${
                idx === photoIdx ? "active" : ""
              }`}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default RookiePhotoSlider;
