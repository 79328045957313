import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { getAvailableNamespaces } from "../../../translations";
import {
  PARAM_ROOKIE_PROFILE,
  PARAM_USER_GROUP,
} from "../../../utilities/constants";
import { getUserGroup } from "../../../utilities/constants/user";
import { useLocation } from "react-router-dom";
import {
  getAppLink,
  redirectToExternalLink,
  remapURLSearchParams,
} from "../../../utilities/utils";
import { useCallback } from "react";
import { APP_ROUTE_SIGNUP_LEADER_SOCIAL } from "../../../utilities/constants/appRouteNames";
import {  InfoLightCircleIcon } from "../../../assets/icons/icons";
import blurredSurprises1 from "../../../assets/images/mySurprises/blurred_img_1.png";
import blurredSurprises2 from "../../../assets/images/mySurprises/blurred_img_2.png";
import blurredSurprises3 from "../../../assets/images/mySurprises/blurred_img_3.png";
import blurredSurprises4 from "../../../assets/images/mySurprises/blurred_img_4.png";
import mySurprises from "../../../assets/images/mySurprises/mySurprises.png";
import newSurprises from "../../../assets/images/mySurprises/new_surprises.png";
import Media from "../../../components/Media";
import Button from "../../../components/Button";
import Tippy from "@tippyjs/react";

const useStyles = createUseStyles((theme) => ({
  surpriseTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    fontSize: 20,
    fontWeight: "700",
    lineHeight: "28px",
    margin: "16px 0",
    '& h4': {
      margin: 0
    },
    '& svg': {
      cursor: 'pointer'
    }
  },
  backgroundBanner: {
    padding: "16px",
    borderRadius: "14px",
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    display: 'flex',
    flexDirection: 'column',
    gap: 16
  },
  surpriseListContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 16,
    marginBottom: 8,
  },

  surpriseItem: {
    borderRadius: '10px',
    aspectRatio: '1/1',
  },
  mediaWrapper: {
    position: "relative",
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: '10%',

    '& img': {
      width: '88px',
      height: '88px',
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  media: {
    width: '100%',
    height: '100%'
  },

  surpriseCta: {
    width: "100%",
    padding: [8, 30, 8, 30],
    fontSize: 16,
    fontWeight: "600",
    "& span": {
      display: "flex",
      gap: 8,
    },
  },
  surpriseLink: {
    textAlign: "center",
    textDecoration: "underline",
    fontSize: "14px",
    fontWeight: 700,
    cursor: 'pointer'
  },
  tippy: {
    padding: '16px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    width: 320,
    height: 230,
    borderRadius: '14px',
  },
  tooltipContent:{
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    '& p':{
      margin: 0,
      fontWeight: 700,
    },
    '& img':{
      width: 84,
      height: 90
    }
  }
}));

const blurredImages = [
  blurredSurprises1,
  blurredSurprises2,
  blurredSurprises3,
  blurredSurprises4,

]

const RookieSurpriseSection = ({ rookieProfile }) => {
  const classes = useStyles();
  const [t] = useTranslation(getAvailableNamespaces(), {
    react: { useSuspense: false },
  });
  const { search } = useLocation();
  const gender = rookieProfile.gender.key_name;

  const onGiftHandler = useCallback(() => {
    redirectToExternalLink(
      getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
        ...remapURLSearchParams(search),
        {
          name: PARAM_ROOKIE_PROFILE,
          value: rookieProfile?.username, 
        },
        {
          name: PARAM_USER_GROUP,
          value: getUserGroup(),
        },
      ])
    );
  }, [search, rookieProfile]);

  return (
    <div>
      <div className={classes.surpriseTitleContainer}>
        <h4>
          {t("rookie_profile_page.surprises_section.title", {ROOKIE_NAME: rookieProfile?.type_attributes?.first_name})}
        </h4>
        <Tippy
          theme="light"
          content={
            <div className={classes.tooltipContent}>
                <p>{t('rookie_profile_page.surprises_section.what_is_surprise_title')}</p>
                <img src={mySurprises} />
                <div>{t('rookie_profile_page.surprises_section.what_is_surprise_text')}</div>
            </div>
          }
          placement={'bottom'}
          trigger="click"
          className={classes.tippy}
          inlinePositioning={true}
          interactive={true}
        >
            <span>
              <InfoLightCircleIcon />
            </span>
        </Tippy>
      </div>

      <div className={classes.backgroundBanner}>
      <Tippy
          theme="light"
          content={
            <div className={classes.tooltipContent}>
                <p>{t('rookie_profile_page.surprises_section.what_get_title')}</p>
                <img src={mySurprises} />
                <div>{t('rookie_profile_page.surprises_section.what_get_text')}</div>
            </div>
          }
          placement={'bottom'}
          trigger="click"
          className={classes.tippy}
          inlinePositioning={true}
          interactive={true}
        >
          <span className={classes.surpriseLink}>
            {t("rookie_profile_page.surprises_section.subtitle")}
          </span>
        </Tippy>

        <div className={classes.surpriseListContainer}> 
          {blurredImages.map(item => (<div className={classes.surpriseItem} key={item}>
            <div className={classes.mediaWrapper} >
                <img src={newSurprises}/> 
                <Media 
                  image={item} 
                  variant={'cover'} 
                  className={classes.media}
                />
              </div>
            </div>)
          )}
        </div>

          <Button data-primary className={classes.surpriseCta} onClick={onGiftHandler}>
            {t("rookie_profile_page.surprises_section.surprise_cta")}
          </Button>

          <span className={classes.surpriseLink} onClick={onGiftHandler}>
            {t("rookie_profile_page.surprises_section.view_surprise")}
          </span>
      </div> 
    </div>
  );
};

export default RookieSurpriseSection;
