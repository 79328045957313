import { useState } from "react";
import { createUseStyles } from "react-jss";
import ChoosePaymentMethodSection from "../ChoosePaymentMethodSection/ChoosePaymentMethodSection";
import Popover from "../Popover";
import mastercardLogo from '../../assets/images/mastercard_logo.svg';
import visaLogo from '../../assets/images/visa_logo.svg';
import { RedirectIcon } from "../../assets/icons/icons";
import SessionExpireTimer from "./SessionExpireTimer";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../translations";
import { CreditCardsName } from "../ChoosePaymentMethodSection/helpers";
import { useViewportSize } from "../../hooks/useViewportSize";


const useStyles = createUseStyles((theme) => ({
    subTitle: {
        fontSize: '16px',
        fontWeight: '700',
        textAlign: 'center',
        color: '#000000',
        marginBottom: '16px'
    },
    modalBody: {
        padding: '0 24px 24px',
        maxWidth: '394px',
        height: '100%',
        display: 'grid',
        gridTemplateRows: '1fr auto',
        justifyContent: 'center',
    },
    modalRoot: {
        width: '394px',
        height: '600px',
    },
    infoBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        marginTop: '16px'
    },
    iconContainer: {
        display: 'flex',
        gap: '16px',
        alignItems: "center",
        textTransform: 'uppercase',
        fontWeight: '500',
        lineHeight: '24px',
        "& img": {
            width: '22px',
            height: '16px'
        }
    },
    processedInfo: {
        fontSize: '10px',
        fontWeight: '300',
        color: '#A8A8A8',
        opacity: '0.8',
        "& svg": {
            width: '12px',
            height: '10px'
        }
    },
    timer: {
        textAlign: 'center',
        margin: '8px 0 16px 0',
        "& span": {
            fontSize: '12px',
            fontWeight: '600',
            color: '#A8A8A8',
        }
    },
    '@media (max-width: 768px)': {
        modalRoot: {
            width: '100%',
            height: '100%',
            borderRadius: '0 !important',
        },
        root: {
            height: '100%',
        },
        modalBody: {
            margin: '0 auto',
        },
    },
    styledAddOtherMethod: ({ height, isUpdateCreditCard, isMonthlySupport }) => ({
        borderRadius: '18px',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.1)',
        marginTop: '13px',
        height: '100%',
        minHeight: isUpdateCreditCard
          ? '430px'
          : isMonthlySupport
          ? `calc(${height}px - 250px)`
          : `calc(${height}px - 425px)`,
    
        // Media queries
        [theme.mediaQueries.m]: {
          minHeight: isUpdateCreditCard ? '430px' : isMonthlySupport ? '430px' : '260px',
        },
    
        '& iframe': {
          borderRadius: '18px',
          border: 'none',
        },
      }),
}));
export const PaymentMethodVariantIframe = ({ onClose, dataToShow, selectedPackage }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const {height} = useViewportSize()
    const classes = useStyles({height});
    const [variant, setVariant] = useState()
    const SESSION_DURATION = 5 // minutes
    const onTimeExpired = () => {
        onClose()
    }
    return (
        <Popover onClose={onClose}
            withHeader
            title={t('morgis_iframe_modal.welcome')}
            className={classes.modalRoot}
            bodyClassName={classes.modalBody}>
            <div>
                <div>
                    {!!selectedPackage && <div className={classes.subTitle}>{t('morgis_iframe_modal.buy_morgis', { Morgis: selectedPackage.micromorgi_count, CURRENCY: '$', AMOUNT: selectedPackage.price })}</div>}
                </div>
                <div>
                    <ChoosePaymentMethodSection onChange={setVariant} label={t('morgis_iframe_modal.set_payment_method')} paymentsList={dataToShow?.urls} />
                </div>
                <div className={classes.infoBox}>
                    <div className={classes.iconContainer}>
                        <img src={visaLogo} /> <img src={mastercardLogo} /> {t('morgis_iframe_modal.pay_with_credit_or_debit_card')}
                    </div>
                    <a className={classes.processedInfo} href={dataToShow?.urls[CreditCardsName.CCBIL_CREDIT_CARD]}>
                        {t('morgis_iframe_modal.processed_by_trustpay')} {t('morgis_iframe_modal.ccbill')} <RedirectIcon />
                    </a>
                    <div className={classes.timer}>
                        <SessionExpireTimer minutes={SESSION_DURATION} onComplete={onTimeExpired} isReInitTimer />
                    </div>
                </div>
                <div>
                    {t('morgis_iframe_modal.billing_to', { USERNAME: dataToShow?.user?.username})}
                </div>
                <div className={classes.styledAddOtherMethod}>
                    <iframe src={variant} height={'100%'} width={'100%'} />
                </div>
            </div>
        </Popover>
    )
}