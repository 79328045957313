import cx from 'classnames';
import { useState } from "react";
import { createUseStyles } from "react-jss";
import { remapAndFilterAPMLinks, SocialPlatformType } from "./helpers";

import mastercardLogo from '../../assets/images/mastercard_logo.svg';
import visaLogo from '../../assets/images/visa_logo.svg';
import { useTranslation } from 'react-i18next';
import { getAvailableNamespaces } from '../../translations';

const useStyles = createUseStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& label': {
            alignSelf: 'center',
        }
    },
    paymentMethodsList: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px',
        maxHeight: '77px', /* Показываем только первую строку (зависит от высоты элементов) */
        overflow: 'hidden',
        transition: 'max-height 0.3s ease-in-out'
    },
    paymentMethod: {
        flex: '1 0 30%',
        borderRadius: '8px',
        background: 'rgba(232, 232, 232, 0.75)',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        height: '77px',
        padding: '0px 8px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        flex: '1 0 0',
        maxWidth: '30%',
        cursor: 'pointer',
        minWidth: '98px',
    },
    open: {
        maxHeight: 'none',
    },
    logo: {
        flex: '1',
        width: '66.941px',
    },
    creditCardWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        "& p": {
            color: '#000',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px', /* 171.429% */
            textAlign: 'center',
            margin: '0'
        },
    },
    creditCardImages: {
        display: 'flex',
        justifyContent: 'space-around',
        '& img': {
            flex: '1',
            maxWidth: '40%'
        }
    },
    selectedPaymentMethod: {
        borderRadius: '8px',
        border: '2px solid #8649D1',
        background: 'rgba(225, 225, 225, 0.75)',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'
    }
});

const ChoosePaymentMethodSection = ({ onChange, label,  paymentsList}) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const [showMore] = useState(false)
    const classes = useStyles()
    const APMLinksList = remapAndFilterAPMLinks(paymentsList)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState()

    const onSelectPaymentMethodHandler = (paymentUrl) => {
        setSelectedPaymentMethod(paymentUrl)
        onChange(paymentUrl)
    }
    return (
        <div className={classes.root}>
            {label ? <label>{label}</label> : <></>}
            <div className={cx(classes.paymentMethodsList, showMore ? classes.open : undefined)}>
                {APMLinksList.map((item) => {
                    const isCreditCard = item.name === SocialPlatformType.CREDIT_CARD
                    return (
                        <div key={item.url} className={cx(
                            classes.paymentMethod,
                            selectedPaymentMethod === item.url ? classes.selectedPaymentMethod : undefined)}
                            onClick={() => { onSelectPaymentMethodHandler(item.url) }}
                        >
                            {isCreditCard ?
                                <div className={cx(classes.logo, classes.creditCardWrapper)}>
                                    <p>{t('morgis_checkout_modal.payment_method.credit_card_label')}</p>
                                    <div className={classes.creditCardImages}>
                                        <img src={visaLogo} />
                                        <img src={mastercardLogo} />
                                    </div>
                                </div>
                                :
                                <img className={classes.logo} src={item.icon} />}
                        </div>
                    )
                })}
            </div>
        </div >
    )
}
export default ChoosePaymentMethodSection