import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, Controller, useForm } from "react-hook-form";
import { createUseStyles } from "react-jss";
import * as yup from 'yup';
import AutoCompleteAddressController from "../../../components/AutoCompleteAddress/AutoCompleteAddressController";
import Button from "../../../components/Button";
import InputText from "../../../components/InputText";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import Popover from "../../../components/Popover";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import axios from '../../../utilities/axios';
import { PaymentMethodVariantIframe } from "../../../components/PaymentMethodVariantIframe/PaymentMethodVariantIframe";
import { useState } from "react";
import Spinner from "../../../components/Spinner";

const useStyles = createUseStyles({
    root: {},
    bodyRoot: {
        display: 'flex',
        width: '375px',
        height: '667px',
        padding: '24px 8px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        flexShrink: 0,
        paddingTop: 0
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        height: '100%'
    },
    submit: {
        marginTop: 'auto'
    },
    '@media (max-width: 768px)': {
        root: {
            height: '100%',
            width: '100%',
            top: '0',
            left: 'unset',
            transform: 'none',
            borderRadius: 0
        },
        bodyRoot: {
            margin: '0 auto',
            height: '100%'
        },
        form: {
            gap: '6px',
        }
    }
});

const CHECKOUT_FIELDS = {
    customerName: 'customerName',
    email: 'email',
    phone: 'phone',
    street: 'street',
    paymentMethod: 'paymentMethod',
    address: 'address',
    city: 'city',
    zipCode: 'zip_code',
    rowAddress: 'raw_address',
    countryName: 'country_name',
    countryCode: 'country_code',
    stateCode: 'state_code'
};

export const AddressSchema = yup.object({
    [CHECKOUT_FIELDS.address]: yup.string().min(1, {
        message: 'validation:required_street'
    }),
    [CHECKOUT_FIELDS.city]: yup.string().min(1, {
        message: 'validation:required_city'
    }),
    [CHECKOUT_FIELDS.zipCode]: yup.string().optional(),
    [CHECKOUT_FIELDS.rowAddress]: yup.string().optional(),
    [CHECKOUT_FIELDS.countryName]: yup.string().optional(),
    [CHECKOUT_FIELDS.countryCode]: yup.string().optional(),
    [CHECKOUT_FIELDS.stateCode]: yup.string().optional()
})

const MorgisCheckoutValidationSchema = yup.object().shape({
    [CHECKOUT_FIELDS.customerName]: yup
        .string()
        .required('validation:required_first_name')
        .test(
            'has-two-words',
            'validation:required_last_name',
            (value) => value && value.trim().split(/\s+/).length >= 2
        ),
    [CHECKOUT_FIELDS.email]: yup
        .string()
        .email('validation:invalid_email')
        .required('validation:required_email'),
    [CHECKOUT_FIELDS.phone]: yup.string(),
    [CHECKOUT_FIELDS.street]: AddressSchema.optional()
});

export const paymentTypes = {
    subscription: 'subscription',
    micromorgi: 'micromorgi'
}

const MorgisCheckoutModal = ({ onClose, morgisPackage, subscriptionPlan, paymentType, rookieId, selectedPackage }) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const [isOpenPaymentMethodVariantIframe, setIsOpenPaymentMethodVariantIframe] = useState(false)
    const [paymentsMethods, setPaymentsMethods] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const methods = useForm({
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {
            [CHECKOUT_FIELDS.customerName]: '',
            [CHECKOUT_FIELDS.email]: '',
            [CHECKOUT_FIELDS.phone]: '',
            [CHECKOUT_FIELDS.street]: '',
            [CHECKOUT_FIELDS.paymentMethod]: '',
            [CHECKOUT_FIELDS.RowAddress]: '',
            [CHECKOUT_FIELDS.address]: {
                [CHECKOUT_FIELDS.city]: '',
                [CHECKOUT_FIELDS.countryCode]: '',
                [CHECKOUT_FIELDS.countryName]: '',
                [CHECKOUT_FIELDS.stateCode]: '',
                [CHECKOUT_FIELDS.zipCode]: ''
            },
        },
        resolver: yupResolver(MorgisCheckoutValidationSchema),
    });

    const { handleSubmit, register, formState: { touched, errors }, control, setError } = methods;

    const onSubmit = async (data) => {
        const dataToSend = {
            type: "leader",
            phone_number: data[CHECKOUT_FIELDS.phone],
            email: data[CHECKOUT_FIELDS.email],
            customer_name: data [CHECKOUT_FIELDS.customerName],
            payment_type: paymentType,
            micromorgi_package_id: morgisPackage?.id,
            subscription_package_id: subscriptionPlan?.id,
            rookie_id: rookieId,
            city: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.city],
            country_name: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.countryName],
            country_code: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.countryCode],
            state_code: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.stateCode],
            zip_code: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.zipCode],
            address: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.address],
            raw_address: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.rowAddress],
        }
        try{
            setIsLoading(true)
            const res = await  axios.post(`/auth/signup/anonymous`, dataToSend)
            setPaymentsMethods(res.data)
            setIsOpenPaymentMethodVariantIframe(true)
        } catch (err) {
            if(err) {
                const errorKey = Object.keys(err.response.data)[0]
                setError(errorKey, { message: err.response.data[errorKey][0]})
            }
        } finally {
            setIsLoading(false)
        }
    };

    return (
        <>
            <Popover
                onClose={onClose}
                withHeader
                title={t('morgis_checkout_modal.title')}
                className={classes.root}
                bodyClassName={classes.bodyRoot}
            >
                {isLoading && <Spinner/>}
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        <InputText
                            name={CHECKOUT_FIELDS.customerName}
                            errors={errors[CHECKOUT_FIELDS.customerName]}
                            touched={touched[CHECKOUT_FIELDS.customerName]}
                            ref={register}
                            label={`${t('morgis_checkout_modal.customer_name.label')}*`}
                            placeholder={t('morgis_checkout_modal.customer_name.placeholder')}
                        />
                        <InputText
                            name={CHECKOUT_FIELDS.email}
                            errors={errors[CHECKOUT_FIELDS.email]}
                            touched={touched[CHECKOUT_FIELDS.email]}
                            ref={register}
                            label={`${t('morgis_checkout_modal.email.label')}*`}
                            placeholder={t('morgis_checkout_modal.email.placeholder')}
                        />

                        <Controller
                            name={CHECKOUT_FIELDS.phone}
                            control={control}
                            render={({ onChange }) => (
                                <PhoneNumberInput
                                    onChange={onChange}
                                    labels={[t('morgis_checkout_modal.phone.phone_code_label'), t('morgis_checkout_modal.phone.phone_label')]}
                                />
                            )}
                        />
                        <AutoCompleteAddressController 
                            name={CHECKOUT_FIELDS.street} 
                            innerFields={
                                [
                                    [CHECKOUT_FIELDS.rowAddress],
                                    [CHECKOUT_FIELDS.address],
                                    [CHECKOUT_FIELDS.city],
                                    [CHECKOUT_FIELDS.countryCode],
                                    [CHECKOUT_FIELDS.countryName],
                                    [CHECKOUT_FIELDS.stateCode],
                                    [CHECKOUT_FIELDS.zipCode]
                                ]
                            }/>
                        <Button className={classes.submit} type="submit">
                            {t('morgis_checkout_modal.submit')}
                        </Button>
                    </form>
                </FormProvider>
            </Popover>
            {isOpenPaymentMethodVariantIframe &&
                <PaymentMethodVariantIframe
                    onClose={() => { setIsOpenPaymentMethodVariantIframe(false) }}
                    dataToShow={paymentsMethods}
                    selectedPackage={selectedPackage}
                />
            }
        </>
    );
};

export default MorgisCheckoutModal;