import React from "react";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import { useViewportSize } from "../../hooks/useViewportSize";
import {
  PARAM_PERSONA,
  PARAM_UTM_CONTENT,
  ROUTE_LANDING_ROOKIE,
} from "../../utilities/constants";
import AnonymousTourCTA from "./AnonymousTourCTA";
import AnonymousTourSlider from "./AnonymousTourSlider";

const useStyles = createUseStyles((theme) => ({
  anonymousTour: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: ({ vh }) => `calc(${vh}px * 100 - 66px)`,
    justifyContent: "space-around",
    background: "#fff",
    padding: 20,
    [theme.mUp]: {
      padding: 0,
      justifyContent: "center",
      height: ({ vh }) => `calc(${vh}px * 100 - 115px)`,
    },
  },
}));
const AnonymousPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const utmContent = searchParams.get(PARAM_UTM_CONTENT);
  const { vh } = useViewportSize();
  const classes = useStyles({ vh });

  const role = location.pathname === ROUTE_LANDING_ROOKIE ? "rookie" : "leader";
  const getCorrectPersona = () => {
    if (!!searchParams.get(PARAM_PERSONA)) {
      if (
        +searchParams.get(PARAM_PERSONA) > 11 ||
        +searchParams.get(PARAM_PERSONA) < 7
      ) {
        return 0;
      } else {
        return +searchParams.get(PARAM_PERSONA);
      }
    } else {
      return 0;
    }
  };
  const persona = getCorrectPersona();

  return (
    <div className={classes.anonymousTour}>
      <AnonymousTourSlider persona={persona} role={role} />
      <AnonymousTourCTA role={role} />
    </div>
  );
};

export default AnonymousPage;
