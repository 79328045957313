import React from 'react';
import {createUseStyles, useTheme} from 'react-jss';
import cx from "classnames";
import PropTypes from "prop-types";
import flattenChildren from 'react-keyed-flatten-children'
import {useViewportSize} from "../hooks/useViewportSize";

const useStyles = createUseStyles(theme => ({
	root: {
		display: 'grid',
		gridAutoColumns: '1fr',
		justifyContent: 'start',
		padding: theme.spacing * 2,
		margin: 0,
		listStyle: 'none',
		maxHeight: ({vh}) =>`calc((${vh}px * 100) - ${theme.sizing.headerHeight})`,
		gridAutoFlow: 'row'
	},
	item: {
		position: 'relative',
		'& > *:first-child': {
			borderBottom: `1px solid ${theme.colors.darkPurpleOpacity}`
		}
	}
}));


const getTransStyle = (i, isOpen) => {
	const transDelay = 310;
	const transStep = 50;
	return {
		opacity: isOpen ? 1 : 0,
		transform: isOpen ? 'translateY(0)' : 'translateY(-20px)',
		transition: `all ${transDelay}ms ${transDelay + i * transStep}ms`
	}
}

const Menu = ({
				  className,
				  isOpen = false,
				  children,
				  ...props
}) => {

	const theme = useTheme();
	const {vh} = useViewportSize();
	const classes = useStyles({vh, theme});

	// https://github.com/grrowl/react-keyed-flatten-children
	// Use flattenChildren instead of React.Children.map to
	// flatten child elements wrapped inside React.Fragment

	return (
		<ul
			{...props}
			className={cx(classes.root, className)}
		>
			{flattenChildren(children).map((child, i) =>
				child && <li key={i} className={classes.item} style={getTransStyle(i, isOpen)}>
					{child}
				</li>)}
		</ul>
	);
};

Menu.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
};

export default Menu;
