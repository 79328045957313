import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { PlayButton } from "../../../assets/icons/icons";
import Portal from "../../../components/Portal";
import LightBox from "../../../components/LightBox";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import {
  getAppLink,
  redirectToExternalLink,
  remapURLSearchParams,
} from "../../../utilities/utils";
import { APP_ROUTE_SIGNUP_LEADER_SOCIAL } from "../../../utilities/constants/appRouteNames";
import { useLocation } from "react-router-dom";
import {
  PARAM_ROOKIE_PROFILE,
  PARAM_USER_GROUP,
} from "../../../utilities/constants";
import { getUserGroup } from "../../../utilities/constants/user";

const useStyles = createUseStyles((theme) => ({
  slider: {
    width: '100%',
    margin: '0 auto',

    "& *": {
      minWidth: 0,
      minHeight: 0,
    },

    "& .slick-list": {
      maxWidth: "340px",
      [theme.mUp]: {
        maxWidth: "440px",
      },
    },
  },
  rookieVideo: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr",
    height: "100px",
    gridTemplateRows: "100px",
    alignItems: "center",
    margin: [8, 0],
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: 10,
    overflow: "hidden",
    "& video": {
      width: "100%",
    },
    "& > img": {
      width: "100%",
    }
  },
  playVideoText: {
    fontSize: 16,
    position: "absolute",
    top: "75%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    textAlign: "center",
    color: theme.colors.white,
    zIndex: 2,

    "& > p": {
      marginTop: 0
    }
  },
  playIcon: {
    cursor: "pointer",
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
  },
  fileOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background:
      "linear-gradient(rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.12) 25%, rgba(0, 0, 0, 0.133) 75%, rgba(0, 0, 0, 0.67) 100%)",
    backdropFilter: ({ videoIndex }) =>
      videoIndex >= 1 ? "blur(10px)" : "unset",
    zIndex: 1,
    borderRadius: 10,
  },
  customDotsContainer: {
    margin: "0 auto",
    display: "flex",
    gap: 4,
    justifyItems: "center",
    position: "relative",
    width: "100%",
    maxWidth: 310,
    top: "-100px",
    zIndex: 2,
    [theme.mUp]: {
    top: "-120px",
    maxWidth: 420,
    },
  },
  signUpText: {
    textDecoration: "underline",
    fontWeight: 700,
    cursor: "pointer",
  },
  customDot: {
    height: 4,
    width: "100%",
    background: theme.colors.white,
    opacity: "0.2",
    borderRadius: "100px",
    position: "relative",
    "&.active": {
      opacity: "1",
    },
  },
}));

const RookieVideoSlider = ({ video, full_name, username }) => {
  const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
  const classes = useStyles({ videoIndex });
  const [playing, setPlaying] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const [currentVideo, setCurrentVideo] = useState(null);
  const videoToShow = video?.slice(0, 2);
  const { search } = useLocation();

  const settings = {
    infinite: false,
    speed: 200,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    adaptiveHeight: true,
    afterChange: (current) => {
      setVideoIndex(current);
    },
  };

  const redirectHandler = useCallback(() => {
    redirectToExternalLink(
      getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
        ...remapURLSearchParams(search),
        {
          name: PARAM_ROOKIE_PROFILE,
          value: username,
        },
        {
          name: PARAM_USER_GROUP,
          value: getUserGroup(),
        },
      ])
    );
  }, [search, username]);

  const handlePlay = (videoUrl) => {
    setCurrentVideo(videoUrl);
    setPlaying(!playing);
  };

  return (
    <>
      <Slider {...settings} className={classes.slider}>
        {videoToShow?.map(({ url, blurred_url }, idx) => (
          <div key={idx} className={classes.rookieVideo}>
            {url ? (
              <video>
                <source src={`${url}#t=0.1`} />
              </video>
            ) : (
              <img src={blurred_url} />
            )}
            <img src={url ? url : blurred_url} />
            <div className={classes.playVideoText}>
              <p>
                {idx >= 1 && (
                  <span
                    className={classes.signUpText}
                    onClick={() => redirectHandler()}
                  >
                    {t('rookie_profile_page.profile_preview.sign_up_plain')}
                  </span>
                )}
                {t(`rookie_profile_page.profile_preview.rookie_video`, {
                  ROOKIE_NAME: full_name,
                  context: `${idx}`,
                })}
              </p>
            </div>
            {playing && currentVideo === url && (
              <Portal>
                <LightBox
                  media={[{ type: "video", url: url }]}
                  plyrOptions={{
                    controls: [
                      "play",
                      "progress",
                      "current-time",
                      "mute",
                      "volume",
                      "settings",
                      "fullscreen",
                    ],
                  }}
                  autoplay
                  onClose={() => setPlaying(false)}
                />
              </Portal>
            )}
            <div className={classes.fileOverlay} />
            <span
              className={classes.playIcon}
              onClick={() => {
                idx === 0 && handlePlay(url);
              }}
            >
              <PlayButton />
            </span>
          </div>
        ))}
      </Slider>
      {video?.length > 1 && <div className={classes.customDotsContainer}>
        {video?.map((_, idx) => (
          <div
            key={idx}
            className={`${classes.customDot} ${
              idx === videoIndex ? "active" : ""
            }`}
          />
        ))}
      </div>}
    </>
  );
};
export default RookieVideoSlider;
